import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { DeviceSpecification, DeviceType } from "../../../models/IDevice";
import { LoraWanDeviceSpecAbpV1_0_X, LoraWanDeviceSpecAbpV1_1, LoraWanDeviceSpecOtaaV1_0_X, LoraWanDeviceSpecOtaaV1_1 } from "../../../models/requests/devices/IAddDeviceRequest";
import { IUser } from "../../../models/IUser";

export type IAddDeviceDialogProps = {
    openDialog: boolean
    selectableUsers?: IUser[]
    handleClose: () => void
    handleAddDevice: (name: string, description: string | null, devEui: string, otaaV1_0_X: LoraWanDeviceSpecOtaaV1_0_X | null, otaaV1_1: LoraWanDeviceSpecOtaaV1_1 | null, abpV1_0_X: LoraWanDeviceSpecAbpV1_0_X | null, abpV1_1: LoraWanDeviceSpecAbpV1_1 | null, deviceType: string, ownerId?: string | null) => void;
};

export default function AddDeviceDialog({ openDialog, selectableUsers, handleClose, handleAddDevice }: IAddDeviceDialogProps) {
    const { handleSubmit, watch, control } = useForm();
    const watchDevSpec = watch("devSpec", DeviceSpecification.OTAA_1_0_x);

    function onSubmit(data: any) {
        const ownerId = data.ownerId === undefined ? null : data.ownerId;
        switch (data.devSpec) {
            case (DeviceSpecification.OTAA_1_0_x):
                const otaa_1_0_x: LoraWanDeviceSpecOtaaV1_0_X = {
                    appEui: data.appEui,
                    appKey: data.appKey
                };
                handleAddDevice(data.name, data.description, data.devEui, otaa_1_0_x, null, null, null, data.deviceType, ownerId);
                break;
            case (DeviceSpecification.OTAA_1_1):
                const otaa_1_1: LoraWanDeviceSpecOtaaV1_1 = {
                    appKey: data.appKey,
                    nwkKey: data.nwkKey,
                    joinEui: data.joinEui
                };
                handleAddDevice(data.name, data.description, data.devEui, null, otaa_1_1, null, null, data.deviceType, ownerId);
                break;
            case (DeviceSpecification.ABP_1_0_x):
                const abp_1_0_x: LoraWanDeviceSpecAbpV1_0_X = {
                    devAddr: data.devAddr,
                    appSKey: data.appSKey,
                    nwkSKey: data.nwkSKey
                };
                handleAddDevice(data.name, data.description, data.devEui, null, null, abp_1_0_x, null, data.deviceType, ownerId);
                break;
            case (DeviceSpecification.ABP_1_1):
                const abp_1_1: LoraWanDeviceSpecAbpV1_1 = {
                    devAddr: data.devAddr,
                    fNwkSIntKey: data.fNwkSIntKey,
                    appSKey: data.appSKey,
                    nwkSEncKey: data.nwkSEncKey,
                    sNwkSIntKey: data.sNwkSIntKey
                };
                handleAddDevice(data.name, data.description, data.devEui, null, null, null, abp_1_1, data.deviceType, ownerId);
                break;
        }

        handleClose();
    }

    const handleAddDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleAddDialogClose}
        >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Add Device</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill the following fields to add a new device
                    </DialogContentText>

                    <Controller
                        name="deviceType"
                        control={control}
                        defaultValue={DeviceType.MetControlHub }
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl margin="normal" sx={{ mr: 2 }}>
                                <InputLabel id="deviceType-select-label">Device Type</InputLabel>
                                <Select
                                    labelId="deviceType-select-label"
                                    label="Device Type"
                                    required
                                    value={value}
                                    onChange={onChange}
                                >
                                    <MenuItem value={DeviceType.MetControlHub}>MetControl Hub</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="devSpec"
                        control={control}
                        defaultValue={DeviceSpecification.OTAA_1_0_x}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl margin="normal" sx={{minWidth: 150}}>
                                <InputLabel id="dev-spec-select-label">Device Specification</InputLabel>
                                <Select
                                    labelId="dev-spec-select-label"
                                    label="Device Specification"
                                    required
                                    value={value}
                                    onChange={onChange}
                                >
                                    <MenuItem value={DeviceSpecification.OTAA_1_0_x}>OTAA v1.0.x</MenuItem>
                                    <MenuItem value={DeviceSpecification.OTAA_1_1}>OTAA v1.1</MenuItem>
                                    <MenuItem value={DeviceSpecification.ABP_1_0_x}>ABP v1.0.x</MenuItem>
                                    <MenuItem value={DeviceSpecification.ABP_1_1}>ABP v1.1</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This field is required"
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />

                    <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                margin="normal"
                                label="Description (Optional)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />

                    <Controller
                        name="devEui"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This field is required",
                            maxLength: { value: 16, message: "DevEUI must be 16 characters long" },
                            minLength: { value: 16, message: "DevEUI must be 16 characters long" },
                            pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="DevEUI"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                inputProps={{ maxLength: 16 }}
                                error={invalid}
                                helperText={error ? error.message : "The 16-digit hexadecimal DevEUI value found on your device."}
                            />
                        )}
                    />

                    {(watchDevSpec === DeviceSpecification.OTAA_1_0_x || watchDevSpec === DeviceSpecification.OTAA_1_1) &&
                        <Controller
                            name="appKey"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This field is required",
                                maxLength: { value: 32, message: "AppKey must be 32 characters long" },
                                minLength: { value: 32, message: "AppKey must be 32 characters long" },
                                pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                            }}
                            render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                <TextField
                                    margin="normal"
                                    label="AppKey"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 32 }}
                                    error={invalid}
                                    helperText={error ? error.message : "The 32-digit hexadecimal AppKey value that your device vendor provided."}
                                />
                            )}
                        />
                    }

                    {watchDevSpec === DeviceSpecification.OTAA_1_0_x &&
                        <Controller
                            name="appEui"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This field is required",
                                maxLength: { value: 16, message: "AppEUI must be 16 characters long" },
                                minLength: { value: 16, message: "AppEUI must be 16 characters long" },
                                pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                            }}
                            render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                <TextField
                                    margin="normal"
                                    label="AppEUI"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 16 }}
                                    error={invalid}
                                    helperText={error ? error.message : "The 16-digit hexadecimal AppEUI that your device vendor provided."}
                                />
                            )}
                        />
                    }

                    {watchDevSpec === DeviceSpecification.OTAA_1_1 &&
                        <>
                            <Controller
                                name="nwkKey"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This field is required",
                                    maxLength: { value: 32, message: "NwkKey must be 32 characters long" },
                                    minLength: { value: 32, message: "NwkKey must be 32 characters long" },
                                    pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                                }}
                                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                    <TextField
                                        margin="normal"
                                        label="NwkKey"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{ maxLength: 32 }}
                                        error={invalid}
                                        helperText={error ? error.message : "The 32-digit hexadecimal NwkKey value that your device vendor provided."}
                                    />
                                )}
                            />

                            <Controller
                                name="joinEui"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This field is required",
                                    maxLength: { value: 16, message: "JoinEUI must be 16 characters long" },
                                    minLength: { value: 16, message: "JoinEUI must be 16 characters long" },
                                    pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                                }}
                                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                    <TextField
                                        margin="normal"
                                        label="JoinEUI"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{ maxLength: 16 }}
                                        error={invalid}
                                        helperText={error ? error.message : "The 16-digit hexadecimal JoinEUI value that your device vendor provided."}
                                    />
                                )}
                            />
                        </>
                    }

                    {(watchDevSpec === DeviceSpecification.ABP_1_0_x || watchDevSpec === DeviceSpecification.ABP_1_1) &&
                        <Controller
                            name="devAddr"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This field is required",
                                maxLength: { value: 8, message: "DevAddr must be 8 characters long" },
                                minLength: { value: 8, message: "DevAddr must be 8 characters long" },
                                pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                            }}
                            render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                <TextField
                                    margin="normal"
                                    label="DevAddr"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 8 }}
                                    error={invalid}
                                    helperText={error ? error.message : "The 8-digit hexadecimal DevAddr that your device vendor provided."}
                                />
                            )}
                        />
                    }

                    {watchDevSpec === DeviceSpecification.ABP_1_0_x &&
                        <Controller
                            name="nwkSKey"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This field is required",
                                maxLength: { value: 32, message: "NwkSKey must be 32 characters long" },
                                minLength: { value: 32, message: "NwkSKey must be 32 characters long" },
                                pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                            }}
                            render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                <TextField
                                    margin="normal"
                                    label="NwkSKey"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 32 }}
                                    error={invalid}
                                    helperText={error ? error.message : "The 32-digit hexadecimal NwkSKey value that your device vendor provided."}
                                />
                            )}
                        />
                    }

                    {watchDevSpec === DeviceSpecification.ABP_1_1 &&
                        <>
                            <Controller
                                name="nwkSEncKey"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This field is required",
                                    maxLength: { value: 32, message: "NwkSEncKey must be 32 characters long" },
                                    minLength: { value: 32, message: "NwkSEncKey must be 32 characters long" },
                                    pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                                }}
                                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                    <TextField
                                        margin="normal"
                                        label="NwkSEncKey"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{ maxLength: 32 }}
                                        error={invalid}
                                        helperText={error ? error.message : "The 32-digit hexadecimal NwkSEncKey that your device vendor provided."}
                                    />
                                )}
                            />
                            <Controller
                                name="fNwkSIntKey"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This field is required",
                                    maxLength: { value: 32, message: "FNwkSIntKey must be 32 characters long" },
                                    minLength: { value: 32, message: "FNwkSIntKey must be 32 characters long" },
                                    pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                                }}
                                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                    <TextField
                                        margin="normal"
                                        label="FNwkSIntKey"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{ maxLength: 32 }}
                                        error={invalid}
                                        helperText={error ? error.message : "The 32-digit hexadecimal FNwkSIntKey that your device vendor provided."}
                                    />
                                )}
                            />
                            <Controller
                                name="sNwkSIntKey"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "This field is required",
                                    maxLength: { value: 32, message: "SNwkSIntKey must be 32 characters long" },
                                    minLength: { value: 32, message: "SNwkSIntKey must be 32 characters long" },
                                    pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                                }}
                                render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                    <TextField
                                        margin="normal"
                                        label="SNwkSIntKey"
                                        type="text"
                                        fullWidth
                                        variant="outlined"
                                        required
                                        value={value}
                                        onChange={onChange}
                                        inputProps={{ maxLength: 32 }}
                                        error={invalid}
                                        helperText={error ? error.message : "The 32-digit hexadecimal SNwkSIntKey value that your device vendor provided."}
                                    />
                                )}
                            />
                        </>
                    }

                    {(watchDevSpec === DeviceSpecification.ABP_1_0_x || watchDevSpec === DeviceSpecification.ABP_1_1) &&
                        <Controller
                            name="appSKey"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "This field is required",
                                maxLength: { value: 32, message: "AppSKey must be 32 characters long" },
                                minLength: { value: 32, message: "AppSKey must be 32 characters long" },
                                pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                            }}
                            render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                                <TextField
                                    margin="normal"
                                    label="AppSKey"
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    value={value}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 32 }}
                                    error={invalid}
                                    helperText={error ? error.message : "The 32-digit hexadecimal AppSKey value that your device vendor provided."}
                                />
                            )}
                        />
                    }
                    {selectableUsers?.length > 0 &&
                        <Controller
                            name="ownerId"
                            control={control}
                            defaultValue={selectableUsers[0].id}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <FormControl margin="normal">
                                    <InputLabel id="owner-id-select-label">Owner</InputLabel>
                                    <Select
                                        labelId="owner-id-select-label"
                                        label="Owner"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {selectableUsers.map(user =>
                                            <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAddDialogClose(null, null)} variant="outlined">Cancel</Button>
                    <Button type="submit" variant="outlined">Add</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}