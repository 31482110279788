import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

export default function Footer(props: any) {
    return (
        <Stack direction="column" alignItems="center" justifyItems="center" {...props}>
            <Typography variant="body2" color="text.secondary" align="center">
                Financed by: CDTI and Eureka
            </Typography>

            <Stack direction="row" alignItems="center" justifyItems="center" spacing={5} sx={{ maxWidth: { xs: '80vw' }, mr: 2 , my: 2}}>
                <Box component="img" src='logo_cdti.png' alt="LOGO_CDTI" sx={{ maxHeight: 50, display: { md: 'flex' } }} />
                <Box component="img" src='logo_eurostars.png' alt="LOGO_EUROSTARS" sx={{ maxHeight: 80, display: { md: 'flex' } }} />
            </Stack>
        </Stack>
    );
}