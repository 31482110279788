import { createApi } from '@reduxjs/toolkit/query/react'
import { IDevice } from '../../models/IDevice'
import { IGateway } from '../../models/IGateway'
import { IHistoricDataEntry } from '../../models/IHistoricDataEntry'
import { IUser } from '../../models/IUser'
import { ILoginRequest } from '../../models/requests/authentication/ILoginRequest'
import { IRefreshTokenRequest } from '../../models/requests/authentication/IRefreshTokenRequest'
import { IAddDeviceRequest } from '../../models/requests/devices/IAddDeviceRequest'
import { IDeleteDeviceRequest } from '../../models/requests/devices/IDeleteDeviceRequest'
import { IUpdateDeviceLocationRequest } from '../../models/requests/devices/IUpdateDeviceLocationRequest'
import { IUpdateDeviceRequest } from '../../models/requests/devices/IUpdateDeviceRequest'
import { IAddGatewayRequest } from '../../models/requests/gateways/IAddGatewayRequest'
import { IDeleteGatewayRequest } from '../../models/requests/gateways/IDeleteGatewayRequest'
import { IUpdateGatewayRequest } from '../../models/requests/gateways/IUpdateGatewayRequest'
import { IAddUserRequest } from '../../models/requests/users/IAddUsersRequest'
import { IUpdateUserRequest } from '../../models/requests/users/IUpdateUserRequest'
import { ILoginResponse } from '../../models/responses/authentication/ILoginResponse'
import { IRefreshTokenResponse } from '../../models/responses/authentication/IRefreshTokenResponse'
import { IAddDeviceResponse } from '../../models/responses/devices/IAddDeviceResponse'
import { IGetAllDevicesResponse } from '../../models/responses/devices/IGetAllDevicesResponse'
import { IGetSensorHistoricDataResponse } from '../../models/responses/devices/IGetSensorHistoricDataResponse'
import { IAddGatewayResponse } from '../../models/responses/gateways/IAddGatewayResponse'
import { IGetAllGatewaysResponse } from '../../models/responses/gateways/IGetAllGatewaysResponse'
import { IAddUserResponse } from '../../models/responses/users/IAddUserResponse'
import { IGetAllUsersResponse } from '../../models/responses/users/IGetAllUsersResponse'
import { baseQueryWithReauth } from './baseQueryWithReauth'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'Gateway', 'Device'],
  endpoints: builder => ({
    // Auth
    login: builder.mutation<ILoginResponse, ILoginRequest>({
        query: requestData => ({
            url: '/auth/login',
            method: "POST",
            body: requestData
        })
    }),
    refreshToken: builder.mutation<IRefreshTokenResponse, IRefreshTokenRequest>({
        query: requestData => ({
            url: '/auth/refreshtoken',
            method: "POST",
            body: requestData
        })
    }),
    logout: builder.mutation<void, void>({
        query: () => ({
            url: '/auth/logout',
            method: "POST"
        })
    }),
    // Gateways
    addGateway: builder.mutation<IAddGatewayResponse, IAddGatewayRequest>({
        query: requestData => ({
            url: '/gateways',
            method: "POST",
            body: requestData
        }),
        invalidatesTags: ['Gateway']
    }),
    updateGateway: builder.mutation<void, { gatewayId: string, updateGateway: IUpdateGatewayRequest }>({
        query: requestData => ({
            url: `/gateways/${requestData.gatewayId}`,
            method: "PUT",
            body: requestData.updateGateway
        }),
        invalidatesTags: ['Gateway']
    }),
    getAllGateways: builder.query<IGateway[], void>({
        query: () => '/gateways',
        transformResponse: (response: IGetAllGatewaysResponse, meta, arg) => response.data,
        providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Gateway' as const, id })), 'Gateway']
          : ['Gateway']
    }),
    deleteGateway: builder.mutation<void, { gatewayId: string, deleteGateway: IDeleteGatewayRequest }>({
        query: requestData => ({
            method: "DELETE",
            url: `/gateways/${requestData.gatewayId}`,
            body: requestData.deleteGateway
        }),
        invalidatesTags: ['Gateway']
    }),
    // Devices
    addDevice: builder.mutation<IAddDeviceResponse, IAddDeviceRequest>({
        query: requestData => ({
            url: '/devices',
            method: "POST",
            body: requestData
        }),
        invalidatesTags: ['Device']
    }),
    updateDevice: builder.mutation<void, { deviceId: string, updateDevice: IUpdateDeviceRequest }>({
        query: requestData => ({
            url: `/devices/${requestData.deviceId}`,
            method: "PUT",
            body: requestData.updateDevice
        }),
        invalidatesTags: ['Device']
    }),
    updateDeviceLocation: builder.mutation<void, {deviceId: string, updateDeviceLocation: IUpdateDeviceLocationRequest }>({
        query: requestData => ({
            url: `/devices/${requestData.deviceId}/location`,
            method: "PUT",
            body: requestData.updateDeviceLocation
        }),
        invalidatesTags: ['Device']
    }),
    getAllDevices: builder.query<IDevice[], void>({
        query: () => '/devices',
        transformResponse: (response: IGetAllDevicesResponse, meta, arg) => response.data,
        providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Device' as const, id })), 'Device']
          : ['Device']
    }),
    deleteDevice: builder.mutation<void, { deviceId: string, deleteDevice: IDeleteDeviceRequest }>({
        query: requestData => ({
            url: `/devices/${requestData.deviceId}`,
            method: "DELETE",
            body: requestData.deleteDevice
        }),
        invalidatesTags: ['Device']
    }),
    getSensorHistoricData: builder.query<IHistoricDataEntry[], { deviceId: string, sensorId: string, fromDate?: number | null, toDate?: number | null }>({
        query: requestData => {
            const { fromDate, toDate } = requestData;
            return {
                url: `/devices/${requestData.deviceId}/${requestData.sensorId}/history`,
                params: {fromDate, toDate}
            }
        },
        transformResponse: (response: IGetSensorHistoricDataResponse, meta, arg) => response.data
    }),
    // Users
    addUser: builder.mutation<IAddUserResponse, IAddUserRequest>({
        query: requestData => ({
            url: '/users',
            method: 'POST',
            body: requestData
        }),
        invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation<void, {userId: string, updateUser: IUpdateUserRequest}>({
        query: requestData => ({
            url: `/users/${requestData.userId}`,
            method: 'PUT',
            body: requestData.updateUser
        }),
        invalidatesTags: ['User'],
    }),
    getAllUsers: builder.query<IUser[], void>({
        query: () => '/users',
        transformResponse: (response: IGetAllUsersResponse, meta, arg) => response.data,
        providesTags: (result, error, arg) =>
        result
          ? [...result.map(({ id }) => ({ type: 'User' as const, id })), 'User']
          : ['User'],
    }),
    deleteUser: builder.mutation<void, string>({
        query: userId => ({
            url: `/users/${userId}`,
            method: 'DELETE'
        }),
        invalidatesTags: ['User'],
    })
  })
})

export const { 
    useLoginMutation,
    useRefreshTokenMutation,
    useLogoutMutation,

    useAddGatewayMutation,
    useUpdateGatewayMutation,
    useGetAllGatewaysQuery,
    useDeleteGatewayMutation,

    useAddDeviceMutation,
    useUpdateDeviceMutation,
    useUpdateDeviceLocationMutation,
    useGetAllDevicesQuery,
    useDeleteDeviceMutation,
    useGetSensorHistoricDataQuery,

    useAddUserMutation,
    useUpdateUserMutation,
    useGetAllUsersQuery,
    useDeleteUserMutation,
} = apiSlice