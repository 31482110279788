import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IDevice } from "../../../models/IDevice";

export type IUpdateDeviceDialogProps = {
    openDialog: boolean;
    handleClose: () => void;
    device: IDevice;
    handleUpdateDevice: (deviceId: string, name: string, description: string | null, ownerId: string | null) => void;
};

export default function UpdateDeviceDialog({ openDialog, handleClose, device, handleUpdateDevice }: IUpdateDeviceDialogProps) {
    const { handleSubmit, control } = useForm();

    function onSubmit(data: any) {
        handleUpdateDevice(device.id, data.name, data.description, device.ownerId);
        handleClose();
    }

    const handleUpdateDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleUpdateDialogClose}
        >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Edit Device</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change the following fields to update the device
                    </DialogContentText>

                    <Controller
                        name="name"
                        control={control}
                        defaultValue={device.name}
                        rules={{
                            required: "This field is required"
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />

                    <Controller
                        name="description"
                        control={control}
                        defaultValue={device.description}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="Description (Optional)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateDialogClose(null, null)} variant="outlined">Cancel</Button>
                    <Button type="submit" variant="outlined">Update</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}