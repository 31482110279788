import { EditLocationAlt } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import { IDevice } from "../../models/IDevice";
import SensorValue from "./SensorValue";

type DeviceDetailsProps = {
    device?: IDevice | null | undefined,
    onEditLocationTap: () => void
}

export default function DeviceDetails({ device, onEditLocationTap }: DeviceDetailsProps) {
    if (device === null || device === undefined) {
        return (
            <Stack width='100%' height='100%' alignItems="center" justifyContent="center">
                <Typography variant="body1">Select a device to see the details</Typography>
            </Stack>
        )
    } else {
        return (
            <Stack direction="column" width='100%' height='100%' overflow='auto' spacing={1}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">Id:</Typography>
                    <Typography variant="body1">{device.id}</Typography>
                </Stack>
                
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">Name:</Typography>
                    <Typography variant="body1">{device.name}</Typography>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">Location:</Typography>
                    <IconButton onClick={onEditLocationTap}>
                        <EditLocationAlt />
                    </IconButton>
                    <Stack direction="column">
                        <Typography variant="body1">Lat: {device.latitude}</Typography>
                        <Typography variant="body1">Lon: {device.longitude}</Typography>
                    </Stack>
                </Stack>

                {[...device.sensors].sort((s1, s2) => s1.name.localeCompare(s2.name)).map(sensor =>
                    <Stack key={sensor.id} direction="column">
                        <Typography fontWeight='bold' variant="h6" color={sensor.isOnline === true ? "#000000" : "#FF0000"}>
                            {sensor.name} ({sensor.isOnline ? "Online" : "Offline"})
                        </Typography>
                        {Object.keys(sensor.metadata).length > 0 &&
                            Object.keys(sensor.metadata).map(key =>
                                <Typography key={key} variant="h6">
                                    {key[0].toUpperCase()}{key.slice(1)}: {sensor.metadata[key]}
                                </Typography>
                            )
                        }
                        {sensor.readings.map(reading =>
                            <Fragment key={reading.id}>
                                {
                                    !reading.isDebug &&
                                    <Stack direction="row" pr={2} spacing={4} justifyContent="space-between">
                                        <SensorValue type={reading.type} name={reading.name} value={reading.value} units={reading.units} />
                                        <Typography gutterBottom variant="body1">
                                            {reading.timestamp ? new Date(reading.timestamp).toLocaleString() : "NO DATE"}
                                        </Typography>
                                    </Stack>
                                }
                            </Fragment>
                        )}
                    </Stack>
                )}
            </Stack>
        )
    }
}