import CssBaseline from '@mui/material/CssBaseline';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { store } from './app/store';
import PrivateRoute, { PrivateRouteProps } from './components/PrivateRoute/PrivateRoute';
import DevicesPage from './pages/Devices/DevicesPage';
import GatewaysPage from './pages/Gateways/GatewaysPage';
import LoginPage from './pages/Login/LoginPage';
import ProfilePage from './pages/Profile/ProfilePage';
import UsersPage from './pages/Users/UsersPage';
import TwinPage from './pages/Twin/TwinPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#ddddea',
      paper: '#d7d9ff',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        scrollbarWidth: "thin",
        '&::-webkit-scrollbar': {
          width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
          background: "#f1f1f1",
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 6,
          backgroundColor: '#888',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }
    }
  }
});

const defaultProtectedRouteProps: Omit<PrivateRouteProps, 'outlet'> = {
  authenticationPath: '/login',
};

function App() {
  useEffect(() => {
    window.addEventListener('unhandledrejection', function (event) {
      event.preventDefault();
    });
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path='devices' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<DevicesPage />} />} />
            <Route path='gateways' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<GatewaysPage />} />} />
            <Route path='twin' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<TwinPage />} />} />
            <Route path='users' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<UsersPage />} />} />
            <Route path='profile' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<ProfilePage />} />} />
            <Route path='login' element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/devices" replace />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;