import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, FormControl, InputLabel, Select, MenuItem, DialogActions, Button } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { GatewayRegion } from "../../../models/IGateway";
import { IUser } from "../../../models/IUser";

export type IAddGatewayDialogProps = {
    openDialog: boolean;
    selectableUsers?: IUser[];
    handleClose: () => void;
    handleAddGateway: (gatewayEui: string, gatewayName: string, gatewayDescription: string | null, gatewayRegion: GatewayRegion, ownerId: string | null) => void;
};

export default function AddGatewayDialog({ openDialog, selectableUsers, handleClose, handleAddGateway }: IAddGatewayDialogProps) {
    const { handleSubmit, control } = useForm();

    function onSubmit(data: any) {
        handleAddGateway(data.eui, data.name, data.description, data.region, data.ownerId === undefined ? null : data.ownerId);
        handleClose();
    }

    const handleAddDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    return (
        <Dialog
            open={openDialog}
            onClose={handleAddDialogClose}
        >
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Add Gateway</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Fill the following fields to add a new gateway
                    </DialogContentText>

                    <Controller
                        name="eui"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This field is required",
                            maxLength: { value: 16, message: "Gateway EUI must be 16 characters long" },
                            minLength: { value: 16, message: "Gateway EUI must be 16 characters long" },
                            pattern: { value: RegExp("\\b[0-9A-Fa-f]+\\b"), message: "Value must contain hexadecimal digits only" }
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                autoFocus
                                margin="normal"
                                label="Gateway EUI"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                inputProps={{ maxLength: 16 }}
                                error={invalid}
                                helperText={error ? error.message : "Enter the 16-digit hexadecimal EUI code found on your gateway."}
                            />
                        )}
                    />

                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: "This field is required"
                        }}
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                required
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />

                    <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
                            <TextField
                                margin="normal"
                                label="Description (Optional)"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                error={invalid}
                                helperText={error ? error.message : ""}
                            />
                        )}
                    />

                    <Controller
                        name="region"
                        control={control}
                        defaultValue={GatewayRegion.EU868}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl margin="normal" fullWidth>
                                <InputLabel id="rf-region-select-label">RF Region</InputLabel>
                                <Select
                                    labelId="rf-region-select-label"
                                    label="RF Region"
                                    required
                                    value={value}
                                    onChange={onChange}
                                >
                                    <MenuItem value={GatewayRegion.EU868}>EU868</MenuItem>
                                    <MenuItem value={GatewayRegion.US915}>US915</MenuItem>
                                    <MenuItem value={GatewayRegion.AU915}>AU915</MenuItem>
                                    <MenuItem value={GatewayRegion.AS923_1}>AS923-1</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    />

                    {selectableUsers?.length > 0 &&
                        <Controller
                            name="ownerId"
                            control={control}
                            defaultValue={selectableUsers[0].id}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <FormControl margin="normal">
                                    <InputLabel id="owner-id-select-label">Owner</InputLabel>
                                    <Select
                                        labelId="owner-id-select-label"
                                        label="Owner"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {selectableUsers.map(user =>
                                            <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            )}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAddDialogClose(null, null)} variant="outlined">Cancel</Button>
                    <Button type="submit" variant="outlined">Add</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}