import { CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { IDevice } from "../../models/IDevice";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from "react";
import { useGetSensorHistoricDataQuery } from "../../features/api/apiSlice";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { DataType, ILeak } from "../../models/ISensor";

type DeviceHistoryProps = {
    device?: IDevice | null | undefined,
    onLeaksLoaded?: (leaks?: ILeak[] | null) => void,
}

export default function DeviceHistory({ device, onLeaksLoaded }: DeviceHistoryProps) {
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);

    const [sensorId, setSensorId] = useState(device?.sensors[0]?.id ?? "");

    const [selectedLeak, setSelectedLeak] = useState<ILeak | null>(null);

    const { data, isFetching, isError } = useGetSensorHistoricDataQuery(
        {
            deviceId: device?.id,
            sensorId: sensorId,
            fromDate: fromDate?.getTime(),
            toDate: toDate?.getTime()
        },
        {
            skip: !device?.id || !sensorId
        }
    );

    useEffect(() => {
        if (device !== null && device !== undefined) {
            setSensorId(device?.sensors[0]?.id ?? "");
        }
    }, [device]);

    useEffect(() => {
        if (selectedLeak) {
            onLeaksLoaded([selectedLeak]);
        } else {
            onLeaksLoaded(
                data?.filter(x => x.type === DataType.Leak)
                .map((dataEntry) => 
                    dataEntry.value as unknown as ILeak
                ) ?? null
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectedLeak]);

    const columns: GridColDef[] = [
        {
            field: 'timestamp',
            headerName: 'Timestamp',
            type: "dateTime",
            flex: 1

        },
        {
            field: 'reading',
            headerName: 'Reading',
            flex: 1
        },
        {
            field: 'value',
            headerName: 'Value',
            flex: 1
        }
    ];

    const rows = data?.map(x => (
        { 
            id: x.id, 
            timestamp: new Date(x.timestamp), 
            reading: x.readingId, 
            type: x.type,
            value: `${x.type === DataType.Leak ? JSON.stringify(x.value, null, 2) : x.value } ${x.units ? x.units : ""}` 
        }
    ));

    if (device === null || device === undefined) {
        return (
            <Stack width='100%' height='100%' alignItems="center" justifyContent="center">
                <Typography variant="body1">Select a device to see its history</Typography>
            </Stack>
        )
    } else {
        return (
            <Stack width='100%' height='100%' spacing={1} overflow="hidden">
                <Stack direction="row" alignItems="center" spacing={2} marginTop={1}>
                    <Typography variant="body1">
                        {device?.name}
                    </Typography>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="sensor-select">Sensor</InputLabel>
                        <Select
                            labelId="sensor-select"
                            id="sensor-select"
                            value={sensorId}
                            label="Sensor"
                            onChange={(event: SelectChangeEvent) => { setSensorId(event.target.value) }}
                        >
                            {device?.sensors.map(sensor =>
                                <MenuItem key={sensor.id} value={sensor.id}>{sensor.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Stack>

                <Stack direction="row" spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="From Date"
                            value={fromDate}
                            onChange={(newValue) => {
                                setFromDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                        <DatePicker
                            label="To Date"
                            value={toDate}
                            onChange={(newValue) => {
                                setToDate(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </Stack>

                {isFetching &&
                    <Stack width='100%' height='100%' alignItems="center" justifyContent="center">
                        <CircularProgress />
                    </Stack>
                }
                {!isFetching && (isError || data?.length === 0) &&
                    <Stack width='100%' height='100%' alignItems="center" justifyContent="center">
                        <Typography variant="body1">
                            There's no data to show for the selected date range
                        </Typography>
                    </Stack>
                }
                {!isFetching && !isError && data?.length > 0 &&
                    <Stack width='100%' height='100%' overflow="auto">
                        {data?.length > 0 &&
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={100}
                                rowsPerPageOptions={[100]}
                                isRowSelectable={(params: GridRowParams) => params.row.type === DataType.Leak}
                                onSelectionModelChange={(newSelectionModel) => {
                                    if (newSelectionModel?.length > 0) {
                                        const entry = data?.find(x => x.id === newSelectionModel[0]);
                                        if (entry) {
                                            setSelectedLeak(entry.value as unknown as ILeak);
                                        } else {
                                            setSelectedLeak(null);
                                        }
                                    } else {
                                        setSelectedLeak(null);
                                    }
                                }}
                                autoHeight={true}
                                sx={{ backgroundColor: "#ddddea", width: '100%' }}
                            />
                        }
                    </Stack>
                }
            </Stack>
        )
    }
}