import { SearchOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, List, ListItemButton, ListItemText, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { IDevice } from "../../models/IDevice";

type DevicesListProps = {
    devices?: IDevice[],
    selectedDevice?: IDevice | null | undefined,
    onSelectedDevice: (device: IDevice) => void,
    onEditDevice: (device: IDevice) => void,
    onDeleteDevice: (device: IDevice) => void,
    isLoading: boolean
}

export default function DevicesList({ devices, selectedDevice, onSelectedDevice, onEditDevice, onDeleteDevice, isLoading }: DevicesListProps) {
    const [filter, setFilter] = useState("");

    return (
        isLoading ?
            <Stack height='100%' alignItems="center" justifyContent="center">
                <CircularProgress />
            </Stack>
            :
            <Stack direction="column">
                <TextField
                    variant="outlined"
                    placeholder="Search filter"
                    InputProps={{
                        startAdornment: (
                            <IconButton>
                                <SearchOutlined />
                            </IconButton>
                        ),
                    }}
                    value={filter}
                    onChange={(event) => { setFilter(event.target.value) }}
                />
                <List sx={{ maxHeight: '100%', overflow: 'auto' }}>
                    {
                        devices?.filter(device =>
                            device.name.includes(filter) ||
                            device.description.includes(filter) ||
                            device.id.includes(filter)
                        ).map(device =>
                            <ListItemButton
                                key={device.id}
                                selected={device.id === selectedDevice?.id}
                                onClick={() => onSelectedDevice(device)}
                                autoFocus={device.id === selectedDevice?.id}
                                sx={{
                                    "&.Mui-selected": {
                                        backgroundColor: "#3f51b5"
                                    },
                                    "&.Mui-selected:hover": {
                                        backgroundColor: "#3f51b5"
                                    },
                                    ":hover": {
                                        backgroundColor: "#3f51b5"
                                    }
                                }}
                            >
                                <ListItemText>{device.name}</ListItemText>
                                <IconButton onClick={() => onEditDevice(device)}>
                                    <EditOutlined />
                                </IconButton>
                                <IconButton onClick={() => onDeleteDevice(device)}>
                                    <DeleteOutline />
                                </IconButton>
                            </ListItemButton>
                        )
                    }
                </List>
            </Stack>
    )
}