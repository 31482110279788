import { Button, Card, CardActions, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import { IGateway } from "../../models/IGateway";

type GatewayProps = {
    gateway: IGateway;
    showActions: Boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

const Gateway = ({ gateway, showActions, onEditClick, onDeleteClick }: GatewayProps) => {
    return (
        <Card sx={{ padding: 2 }}>
            <CardHeader
                title={gateway.name}
                titleTypographyProps={{ fontWeight: 'bold' }}
                subheader={gateway.description}
            />
            <CardContent>
                <Grid container spacing={2} direction="row" alignItems="left" justifyContent="space-between" width='100%'>
                    <Grid item>
                        <Stack>
                            <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                Gateway Id
                            </Typography>
                            <Typography variant="h6" component="div">
                                {gateway.id}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack>
                            <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                Gateway EUI
                            </Typography>
                            <Typography variant="h6" component="div">
                                {gateway.eui}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack>
                            <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                RF Region
                            </Typography>
                            <Typography variant="h6" component="div">
                                {gateway.region}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack>
                            <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                Status
                            </Typography>
                            <Typography variant="h6" component="div" color={gateway.online === true ? "#000000" : "#FF0000"}>
                                {gateway.online ? "Online" : "Offline"}
                            </Typography>
                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack>
                            <Typography gutterBottom fontWeight='bold' variant="h6" component="div">
                                Status Date
                            </Typography>
                            <Typography variant="h6" component="div">
                                {new Date(gateway.connectivityDate).toLocaleString()}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
            {showActions &&
                <CardActions sx={{ justifyContent: "end" }}>
                    <Button size="large" color="primary" variant="outlined" onClick={onEditClick}>
                        Edit
                    </Button>
                    <Button size="large" color="error" variant="outlined" onClick={onDeleteClick}>
                        Delete
                    </Button>
                </CardActions>
            }
        </Card>
    )
};

export default Gateway;