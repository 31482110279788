import { ISensor } from "./ISensor";

export interface IDevice {
    id: string;
    ownerId: string;
    name: string;
    description: string;
    type: string;
    latitude?: number | null;
    longitude?: number | null;
    lastUplinkData?: string | null;
    lastUplinkDate?: number | null;
    metadata: { [key: string] : string };
    sensors: ISensor[];
}

export enum DeviceSpecification {
    OTAA_1_0_x = "OTAA v1.1.x",
    OTAA_1_1 = "OTAA v1.1",
    ABP_1_0_x = "ABP v1.0.x",
    ABP_1_1 = "ABP v1.1"
}

export enum DeviceType {
    MetControlHub = "MetControlHub"
}