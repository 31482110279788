export interface ISensor {
    id: string;
    name: string;
    isOnline: boolean;
    metadata: { [key: string] : string };
    readings: ISensorReading[];
}

export interface ISensorReading {
    id: string;
    name: string;
    type: DataType;
    units?: string | null;
    value?: any | null;
    timestamp?: number | null;
    isDebug?: boolean | null;
}

export enum DataType {
    Boolean,
    Integer,
    Hex,
    Decimal,
    Gps,
    Leak
}

export interface ILeak {
    intensity: number,
    lat: number,
    lon: number
}