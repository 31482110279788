import Map, { HeatmapLayer, Layer, MapRef, Marker, MarkerDragEvent, NavigationControl, Source } from 'react-map-gl';
import { IDevice } from '../../models/IDevice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Stack } from '@mui/material';

import 'mapbox-gl/dist/mapbox-gl.css';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { ILeak } from '../../models/ISensor';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAX_ZOOM_LEVEL = 24;

const heatmapLayer: HeatmapLayer = {
  id: 'heatmap',
  maxzoom: MAX_ZOOM_LEVEL,
  type: 'heatmap',
  paint: {
    'heatmap-weight': ['interpolate', ['linear'], ['get', 'intensity'], 0, 0, 6, 1],
    'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, MAX_ZOOM_LEVEL, 3],
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      0,
      'rgba(33,102,172,0)',
      0.2,
      'rgb(103,169,207)',
      0.4,
      'rgb(209,229,240)',
      0.6,
      'rgb(253,219,199)',
      0.8,
      'rgb(239,138,98)',
      0.9,
      'rgb(255,201,101)'
    ],
    'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
    //'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, 9, 0]
  }
};

type DevicesMapProps = {
    devices?: IDevice[],
    selectedDevice?: IDevice,
    leaks?: ILeak[],
    onSelectedDevice: (device: IDevice) => void,
    editMode: boolean,
    onCancelEdit: () => void,
    onConfirmEdit: (latitude: number, longitude: number) => void
}

const DevicesMap = ({ devices, selectedDevice, leaks, onSelectedDevice, editMode, onCancelEdit, onConfirmEdit }: DevicesMapProps) => {
    const mapRef = useRef<MapRef>();

    const [editCoordinates, setEditCoordinates] = useState({ lng: 0, lat: 0 });
    const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
        setEditCoordinates({
            lng: event.lngLat.lng,
            lat: event.lngLat.lat
        });
    }, []);

    useEffect(() => {
        if (selectedDevice?.longitude && selectedDevice?.latitude) {
            mapRef.current.flyTo({ center: [selectedDevice.longitude, selectedDevice.latitude] })
        }
    }, [selectedDevice]);

    useEffect(() => {
        if (editMode) {
            setEditCoordinates({ lng: selectedDevice?.longitude ?? 0, lat: selectedDevice?.latitude ?? 0});
        }
    }, [selectedDevice, editMode]);

    return (
        <>
            <Map
                initialViewState={{
                    longitude: 2,
                    latitude: 42,
                    zoom: 3.5
                }}
                ref={mapRef}
                style={{ width: '100%', height: '100%' }}
                mapStyle="mapbox://styles/mapbox/streets-v11"
            >
                {editMode &&
                    <Marker
                        latitude={editCoordinates.lat}
                        longitude={editCoordinates.lng}
                        draggable
                        onDrag={onMarkerDrag}
                        color="green"
                    />
                }

                {!editMode && devices?.map(device =>
                    <Marker
                        key={device.id}
                        latitude={device.latitude}
                        longitude={device.longitude}
                        onClick={() => onSelectedDevice(device)}
                    />
                )}
                {!editMode && leaks?.length > 0 &&
                    <Source type="geojson" data={
                        { 
                            type: "FeatureCollection", 
                            features: leaks.map((leak) => {
                                return {
                                type: "Feature", 
                                properties: { intensity: leak.intensity },
                                geometry: { 
                                    type: "Point",
                                    coordinates: [leak.lon, leak.lat] 
                                } 
                            }
                        })
                    }}
                    >
                        <Layer {...heatmapLayer} />
                    </Source>
                }
                <NavigationControl showCompass={false} />
            </Map>
            {editMode &&
                <Stack direction="row-reverse" spacing={2}>
                    <Button variant="contained" onClick={() => onConfirmEdit(editCoordinates.lat, editCoordinates.lng)}>Set</Button>
                    <Button variant="contained" color="error" onClick={onCancelEdit}>Cancel</Button>
                </Stack>
            }
        </>
    )
}

export default DevicesMap;