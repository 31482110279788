import { MsalAuthService, ADT3DSceneAdapter, Theme, ADT3DViewer } from '@microsoft/iot-cardboard-js';
import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';

export default function LoginPage() {
  const adapterRef = useRef<ADT3DSceneAdapter>(
    new ADT3DSceneAdapter(
      new MsalAuthService(
        {
          authority: "https://login.microsoftonline.com/f5f443a2-3d83-4751-ac63-55a70702b3a7",
          clientId: "66414929-6642-4ed0-8f88-0d9ba1c1325e",
          scope: "https://digitaltwins.azure.net/.default",
          redirectUri: process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://metcontrol.encontrol.io"
        }),
      "met-control-digital-twins.api.weu.digitaltwins.azure.net",
      "https://metcontrolstorage.blob.core.windows.net/digital-twins",
      "f5f443a2-3d83-4751-ac63-55a70702b3a7",
      "66d6e79a-30a2-41a0-b306-fe0f1ac578c2",
      process.env.NODE_ENV === "development" ? "http://localhost:7071/api/adt/proxy" : "https://metcontrol.encontrol.io/api/adt/proxy",
      process.env.NODE_ENV === "development" ? "http://localhost:7071/api/blob/proxy" : "https://metcontrol.encontrol.io/api/blob/proxy",
    ));

  const [refreshConfig, setRefreshConfig] = useState(true);
  const [config, setConfig] = useState(null);
  useEffect(() => {
    if (refreshConfig) {
      adapterRef.current.getScenesConfig().then((result) => {
        let blobConfig: any = result.result?.data;
        if (!blobConfig) {
          if (window.location.href.indexOf('/twin') === -1) {
            window.location.href = '/twin';
          }
          return;
        }

        setConfig(blobConfig);
        setRefreshConfig(false);
      }).catch((e) => {
        console.log(e);
        if (window.location.href.indexOf('/twin') === -1) {
          window.location.href = '/twin';
        }
        return;
      })
    }
  }, [refreshConfig]);

  return (
    <Grid container direction="column" width='100%' minHeight="100vh">
      <Grid item sx={{ color: "white" }}>
        <ADT3DViewer
          theme={Theme.Kraken}
          sceneId={'4776d484494342eeab1d6577a1a558bd'}
          scenesConfig={config}
          adapter={adapterRef.current}
        />
      </Grid>
    </Grid >
  );
}