export interface IGateway {
    id: string;
    eui: string;
    region: string;
    name: string;
    description: string;
    online: boolean;
    connectivityDate?: number;
    ownerId: string;
    configuration: IGatewayConfiguration;
}

export interface IGatewayConfiguration {
    cupsEndpoint: string;
    lnsEndpoint: string;
}

export enum GatewayRegion {
    AU915 = "AU915",
    EU868 = "EU868",
    US915 = "US915",
    AS923_1 = "AS923-1"
}