import { Backdrop, Button, CircularProgress, Grid, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import DeviceDetails from "../../components/Devices/DeviceDetails";
import DeviceHistory from "../../components/Devices/DeviceHistory";
import DevicesList from "../../components/Devices/DevicesList";
import DevicesMap from "../../components/Devices/DevicesMap";
import AddDeviceDialog from "../../components/Dialogs/Devices/AddDeviceDialog";
import { useAddDeviceMutation, useDeleteDeviceMutation, useGetAllDevicesQuery, useGetAllUsersQuery, useUpdateDeviceLocationMutation, useUpdateDeviceMutation } from "../../features/api/apiSlice";
import { selectCurrentUser } from "../../features/auth/authSlice";
import { IDevice } from "../../models/IDevice";
import { IUser, UserRole } from "../../models/IUser";
import { LoraWanDeviceSpecAbpV1_0_X, LoraWanDeviceSpecAbpV1_1, LoraWanDeviceSpecOtaaV1_0_X, LoraWanDeviceSpecOtaaV1_1 } from "../../models/requests/devices/IAddDeviceRequest";
import UpdateDeviceDialog from "../../components/Dialogs/Devices/UpdateDeviceDialog";
import GenericDeleteDialog from "../../components/Dialogs/GenericDeleteDialog";
import { ILeak } from "../../models/ISensor";

export default function DevicesPage() {
    const authUser = useAppSelector(selectCurrentUser);

    const { data: devices, isLoading } = useGetAllDevicesQuery();

    const { data: users } = useGetAllUsersQuery();
    const [selectableUsers, setSelectableUsers] = useState<IUser[]>([]);

    const [editMode, setEditMode] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState<IDevice>(null);
    const [selectedLeaks, setSelectedLeaks] = useState<ILeak[] | null>(null);

    const [updateDeviceLocation] = useUpdateDeviceLocationMutation();

    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [addDevice, addDeviceResult] = useAddDeviceMutation();
    const handleAddDevice = (name: string, description: string | null, devEui: string, otaaV1_0_X: LoraWanDeviceSpecOtaaV1_0_X | null, otaaV1_1: LoraWanDeviceSpecOtaaV1_1 | null, abpV1_0_X: LoraWanDeviceSpecAbpV1_0_X | null, abpV1_1: LoraWanDeviceSpecAbpV1_1 | null, deviceType: string, ownerId?: string | null) => {
        setOpenAddDialog(false);
        addDevice({ name, description, devEui, otaaV1_0_X, otaaV1_1, abpV1_0_X, abpV1_1, deviceType, ownerId });
    }

    const [updateDevice, updateDeviceResult] = useUpdateDeviceMutation();
    const handleUpdateDevice = (deviceId: string, name: string, description: string, ownerId: string) => {
        setOpenUpdateDialog(false);
        updateDevice({deviceId: deviceId, updateDevice: {name: name, description: description, ownerId: ownerId}})
    }

    const [deleteDevice, deleteDeviceResult] = useDeleteDeviceMutation();
    const handleDeleteDevice = (deviceId: string) => {
        setOpenDeleteDialog(false);
        deleteDevice({deviceId: deviceId, deleteDevice: {ownerId: selectedDevice.ownerId}})
    }

    useEffect(() => {
        if (selectedDevice) {
            setSelectedDevice(devices.filter(x => x.id === selectedDevice.id)[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devices])

    useEffect(() => {
        if (authUser.role === UserRole.SuperAdmin && users) {
            setSelectableUsers([...users].filter(u => u.role === UserRole.Admin))
        }
    }, [users, authUser.role]);

    return (
        <>
            <Grid container display="flex" spacing={1} direction="row" alignItems="center" justifyContent="center" width='100%' sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12} md={11} sx={{ ml: 1 }} >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="h4">
                            Devices
                        </Typography>

                        {authUser.role !== UserRole.User &&
                            <Button variant="contained" onClick={() => setOpenAddDialog(true)}>Add Device</Button>
                        }
                    </Stack>
                </Grid>

                <Grid item md={11} sx={{ m: 1 }}>
                    <Stack direction="row" spacing={5}>
                        <Stack direction="column" width='100%' height='80vh' spacing={4}>
                            <Paper elevation={4} sx={{ width: '100%', height: '50%', borderRadius: 2 }} >
                                <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                                    <Typography variant="h5" fontWeight="bold">Map {editMode ? `(Editing location for ${selectedDevice.name})` : ""}</Typography>
                                    <DevicesMap
                                        devices={devices}
                                        selectedDevice={selectedDevice}
                                        leaks={selectedLeaks}
                                        onSelectedDevice={(device) => setSelectedDevice(device)}
                                        editMode={editMode}
                                        onCancelEdit={() => setEditMode(false)}
                                        onConfirmEdit={
                                            (latitude, longitude) => {
                                                setEditMode(false);
                                                updateDeviceLocation({
                                                    deviceId: selectedDevice.id,
                                                    updateDeviceLocation: {
                                                        ownerId: selectedDevice.ownerId,
                                                        latitude: latitude,
                                                        longitude: longitude
                                                    }
                                                });
                                            }
                                        }
                                    />
                                </Stack>
                            </Paper>

                            <Stack direction="row" width='100%' height='50%' spacing={4}>
                                <Paper elevation={4} sx={{ width: '50%', height: '100%', borderRadius: 2 }}>
                                    <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                                        <Typography variant="h5" fontWeight="bold">Details</Typography>
                                        <DeviceDetails device={selectedDevice} onEditLocationTap={() => setEditMode(true)} />
                                    </Stack>
                                </Paper>
                                <Paper elevation={4} sx={{ width: '50%', height: '100%', borderRadius: 2 }}>
                                    <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                                        <Typography variant="h5" fontWeight="bold">History</Typography>
                                        <DeviceHistory device={selectedDevice} onLeaksLoaded={(leaks) => setSelectedLeaks(leaks)}/>
                                    </Stack>
                                </Paper>
                            </Stack>
                        </Stack>

                        <Paper elevation={4} sx={{ width: '30%', height: '80vh', borderRadius: 2 }}>
                            <Stack direction="column" width='100%' height='100%' padding={2} spacing={1}>
                                <Typography variant="h5" fontWeight="bold">Devices List</Typography>
                                <DevicesList
                                    devices={devices}
                                    selectedDevice={selectedDevice}
                                    onSelectedDevice={(device) => { setSelectedDevice(device); }}
                                    onEditDevice={(device) => {
                                        setSelectedDevice(device);
                                        setOpenUpdateDialog(true);
                                    }}
                                    onDeleteDevice={(device) => {
                                        setSelectedDevice(device);
                                        setOpenDeleteDialog(true);
                                    }}
                                    isLoading={isLoading}
                                />
                            </Stack>
                        </Paper>
                    </Stack>
                </Grid>
            </Grid >

            {openAddDialog && <AddDeviceDialog openDialog={openAddDialog} selectableUsers={selectableUsers} handleClose={() => setOpenAddDialog(false)} handleAddDevice={handleAddDevice} />}

            {openUpdateDialog && <UpdateDeviceDialog openDialog={openUpdateDialog} handleClose={() => setOpenUpdateDialog(false)} device={selectedDevice} handleUpdateDevice={handleUpdateDevice}  />}

            {openDeleteDialog &&
                <GenericDeleteDialog
                    openDialog={openDeleteDialog}
                    handleClose={() => setOpenDeleteDialog(false)}
                    title={`Do you want to delete ${selectedDevice.name} widh Id ${selectedDevice.id} ?`}
                    message="This action cannot be undone and you will need to create and configure the Device again."
                    handleDelete={() => handleDeleteDevice(selectedDevice.id)}
                />
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={addDeviceResult.isLoading || updateDeviceResult.isLoading || deleteDeviceResult.isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}