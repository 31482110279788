import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, IconButton, Grid } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IAddGatewayInfo } from "../../../models/responses/gateways/IAddGatewayResponse";

export type INewGatewayDataDialogProps = {
    openDialog: boolean
    handleClose: () => void
    gatewayInfo: IAddGatewayInfo | null
};

export default function NewGatewayDataDialog({ openDialog, handleClose, gatewayInfo }: INewGatewayDataDialogProps) {
    const handleDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const handleCopyCups: () => void = () => {
        const textField = document.getElementById('cupsEp') as HTMLInputElement;
        textField.select();
        navigator.clipboard.writeText(gatewayInfo.cupsEndpoint);
    }

    const handleDownload: (fileName: string, fileContent: string) => void = (fileName, fileContent) => {
        const element = document.createElement("a");
        const file = new Blob([fileContent], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    const handleCopyLns: () => void = () => {
        const textField = document.getElementById('lnsEp') as HTMLInputElement;
        textField.select();
        navigator.clipboard.writeText(gatewayInfo.lnsEndpoint);
    }

    return (
        gatewayInfo != null && (
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <DialogTitle>{gatewayInfo.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This values cannot be seen or downloaded again, make sure you get everything you need before closing the dialog.
                    </DialogContentText>

                    <Button size="large" color="primary" variant="outlined" sx={{ mt: 1, mb: 1 }} onClick={() => handleDownload("gatewayClientCert.pem", gatewayInfo.gatewayCertificate)}>
                        Download Gateway Client Certificate
                    </Button>

                    <Button size="large" color="primary" variant="outlined" sx={{ mt: 1, mb: 1 }} onClick={() => handleDownload("gatewayPrivateKey.key", gatewayInfo.gatewayPrivateKey)}>
                        Download Gateway Private Key
                    </Button>

                    <Grid container alignItems="center" direction="row" width="100%" sx={{ mt: 2, mb: 1 }}>
                        <Grid item width="90%">
                            <TextField
                                id="cupsEp"
                                label="CUPS Endpoint"
                                type="url"
                                fullWidth
                                variant="outlined"
                                value={gatewayInfo.cupsEndpoint}
                            />
                        </Grid>
                        <Grid item width="10%" alignContent="center" justifyContent="center">
                            <IconButton aria-label="content-copy" onClick={handleCopyCups}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Button size="large" color="primary" variant="outlined" sx={{ mt: 1, mb: 1 }} onClick={() => handleDownload("cupsCert.pem", gatewayInfo.cupsServerTrust)}>
                        Download CUPS server certificate
                    </Button>

                    <Grid container alignItems="center" direction="row" width="100%" sx={{ mt: 2, mb: 1 }}>
                        <Grid item width="90%">
                            <TextField
                                id="lnsEp"
                                label="LNS Endpoint"
                                type="url"
                                fullWidth
                                variant="outlined"
                                value={gatewayInfo.lnsEndpoint}
                            />
                        </Grid>
                        <Grid item width="10%">
                            <IconButton aria-label="content-copy" onClick={handleCopyLns}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Button size="large" color="primary" variant="outlined" sx={{ mt: 1 }} onClick={() => handleDownload("lnsCert.pem", gatewayInfo.lnsServerTrust)}>
                        Download LNS server certificate
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose(null, null)} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog >
        )
    )
}